import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Footer extends Component {
    render() {
        const { t } = this.props; // Destructure t function for translation

        return (
            <footer className="footer-content">
                <div className="layer footer">
                    <div className="container-fluid">
                        <div className="row footer-top-inner-w3ls">
                            <div className="col-lg-4 col-md-6 footer-top ">
                                <h2>
                                    <a href="https://maps.app.goo.gl/HCi5ZpKB1Mk7iev7A">{t('footer.name')}</a>
                                </h2>
                                <p className="my-3">{t('footer.description')}</p>
                                <p className="my-3">
                                    {t('footer.location')}
                                </p>
                                <p>{t('footer.address')}</p>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-md-0 mt-5">
                                <div className="footer-w3pvt">
                                    <h3 className="mb-3 w3pvt_title">{t('footer.openingHours')}</h3>
                                    <hr />
                                    <ul className="list-info-w3pvt last-w3ls-contact mt-lg-4">
                                        <li>
                                            <p>{t('footer.available')}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 mt-lg-0 mt-5">
                                <div className="footer-w3pvt">
                                    <h3 className="mb-3 w3pvt_title">{t('footer.contactUs')}</h3>
                                    <hr />
                                    <div className="last-w3ls-contact">
                                        <p>
                                            <a href="mailto:oshlakov@seznam.cz">{t('footer.email')}</a>
                                        </p>
                                    </div>
                                    <div className="last-w3ls-contact my-2">
                                        <a href="tel:+420775604390">
                                            <p>{t('footer.phone')}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                <div class="w3ls-footer text-center mt-4">
                    <ul class="list-unstyled w3ls-icons">
                        <li>
                            <a href="mailto:oshlakov@seznam.cz">
							<span class="fa fa-envelope"></span>
						</a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/alexandr.oshlakov.5">
							<span class="fa fa-facebook-f"></span>
						</a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/alexandroshlakov/">
                            <span class="fa fa-instagram"></span>
                           </a>
                        </li>
                        <li>
                            <a href="http://vk.com/club14782212">
							<span class="fa fa-vk"></span>
						</a>
                        </li>
                    </ul>
                </div>
                <div class="move-top text-right"><a href="#home" class="move-top"> <span class="fa fa-angle-up  mb-3" aria-hidden="true"></span></a></div>
            </div>
            {/* <!-- //footer bottom --> */}
        </div>
    </footer>
        )
    }
}
export default withTranslation()(Footer);