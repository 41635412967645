import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './Home.css';
import { Helmet } from 'react-helmet';

class Home extends Component {
    render() {
        const { t } = this.props;
		const structuredData = {
			"@context": "http://schema.org",
			"@graph": [
			  {
				"@type": "HairSalon",
				"name": "Alexandr Oshlakov Salon",
				"address": {
				  "@type": "PostalAddress",
				  "streetAddress": "Mánesova 1598/60",
				  "addressLocality": "Prague",
				  "postalCode": "120 00",
				  "addressCountry": "CZ"
				},
				"telephone": "+420 775 604 390",
				"priceRange": "$",
				"openingHoursSpecification": [{
				  "@type": "OpeningHoursSpecification",
				  "dayOfWeek": [
					"Monday",
					"Tuesday",
					"Wednesday",
					"Thursday",
					"Friday",
					"Saturday",
					"Sunday"
				  ],
				  "opens": "08:00",
				  "closes": "21:00"
				}],
				"url": "http://www.oshlakov.com",
				"image":  "%PUBLIC_URL%/logo.webp",
				"sameAs": [
					"https://www.facebook.com/alexandr.oshlakov.5",
					"https://www.instagram.com/alexandroshlakov/",
					"http://vk.com/club14782212"
					// Add other social media or relevant URLs here
				  ]
			  },
			  {
				"@type": "Service",
				"name": "Haircut",
				"description": "Men's Haircut",
				"price": "300-500 Kč"
			  },
			  {
				"@type": "Person",
				"name": "Alexandr Oshlakov",
				"jobTitle": "Master Stylist",
				"worksFor": {
				  "@type": "HairSalon",
				  "name": "Alexandr Oshlakov Salon"
				},
				"image":  "%PUBLIC_URL%/logo.webp",
				"url": "http://www.oshlakov.com"
			  }
			  // Add other services as needed
			]
		  };
    return (
      <div>
        {/* <!-- //header --> */}
		<Helmet>
          <title>Alexandr Oshlakov Salon</title>
          <meta name="description" content="Alexandr Oshlakov Salon - Professional hairstyling in Prague" />
		  <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        {/* <!-- banner --> */}
        <div class="banner_w3lspvt" id="home">
          <div class="csslider infinity" id="slider1">
            <input type="radio" name="slides" checked="checked" id="slides_1" />
            <input type="radio" name="slides" id="slides_2" />
            <input type="radio" name="slides" id="slides_3" />
            <input type="radio" name="slides" id="slides_4" />

            <ul class="banner_slide_bg">
              <li>
                <div class="slider-info bg1">
                  <div class="bs-slider-overlay">
                    <div class="banner-text">
                      <div class="container">
                        <h2 class="movetxt agile-title text-capitalize">
						{t('homePage.bannerTitle1')} 
                        </h2>
                        <p>
						{t('homePage.bannerDesc1')} 
                          
                        </p>

                        <Link to="/contact" class="btn">
                          {t('homePage.getAppointment')} 
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="slider-info bg2">
                  <div class="bs-slider-overlay1">
                    <div class="banner-text">
                      <div class="container">
                        <h4 class="movetxt agile-title text-capitalize">
							{t('homePage.bannerTitle2')} 
                        </h4>
                        <p>
							{t('homePage.bannerDesc2')} 
                        </p>
                        <Link to="/contact" class="btn">
                          {t('homePage.getAppointment')} 
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="slider-info bg3">
                  <div class="bs-slider-overlay1">
                    <div class="banner-text">
                      <div class="container">
                        <h2 class="movetxt agile-title text-capitalize">
						{t('homePage.bannerTitle3')} 
                        </h2>
                        <p>
						{t('homePage.bannerDesc3')} 
                        </p>
                        <Link to="/contact" class="btn">
                          {t('homePage.getAppointment')} 
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="slider-info bg4">
                  <div class="bs-slider-overlay1">
                    <div class="banner-text">
                      <div class="container">
                        <h4 class="movetxt agile-title text-capitalize">
						{t('homePage.bannerTitle4')} 
                        </h4>
                        <p>
						{t('homePage.bannerDesc4')} 
                        </p>
                        <Link to="/contact" class="btn">
                          {t('homePage.getAppointment')} 
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="navigation">
              <div>
                <label for="slides_1"></label>
                <label for="slides_2"></label>
                <label for="slides_3"></label>
                <label for="slides_4"></label>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- //banner --> */}
        {/* <!-- banner bottom grids --> */}
        <section class="content-info py-5" id="about">
          <div class="container py-md-5">
            <h3 class="heading text-center mb-3 mb-sm-5">{t('homePage.aboutMe')}</h3>

            <div class="info-w3pvt-mid text-center px-lg-5">
              <div class="title-desc text-center px-lg-5">
                <img
                  src="assets/images/about1.jpg"
                  alt="news image"
                  class="img-fluid aboutme-image"
                />
                <p class="px-lg-5">
				{t('homePage.aboutMePar1')} 
                </p>
                <p class="px-lg-5">
				{t('homePage.aboutMePar2')} 
                </p>
                <p class="px-lg-5">
				{t('homePage.aboutMePar3')} 
                </p>
                <p class="px-lg-5">
				{t('homePage.aboutMePar4')} 
                </p>
                <p class="px-lg-5">
				{t('homePage.aboutMePar5')} 
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- //banner bottom grids --> */}

        {/* <!-- /services --> */}
        <section class="services py-5" id="services">
          <div class="container py-md-5">
            <h3 class="heading text-center mb-3 mb-sm-5">{t('services.heading')} </h3>
            <div class="row ab-info">
              <div class="col-md-6 ab-content ab-content1">
                <div class="ab-content-inner">
                  <img
                    src="assets/images/services2.jpg"
                    alt="news image"
                    class="img-fluid service-image"
                  />
                  <div class="ab-info-con">
                    <h4>{t('services.mensHaircut')}</h4>
                  </div>
                </div>
              </div>
              <div class="col-md-6 ab-content ab-content1">
                <div class="ab-content-inner">
                  <img
                    src="assets/images/womanhaircut.jpg"
                    alt="news image"
                    class="img-fluid service-image"
                  />
                  <div class="ab-info-con">
                    <h4>{t('services.womensHaircut')}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="row ab-info second mt-lg-4">
              <div class="col-md-3 ab-content">
                <div class="ab-content-inner">
                  <img
                    src="assets/images/haircolor.jpg"
                    alt="news image"
                    class="img-fluid service-image"
                  />
                  <div class="ab-info-con">
                    <h4>{t('services.hairColoring')}</h4>
                  </div>
                </div>
              </div>
              <div class="col-md-3 ab-content">
                <div class="ab-content-inner">
                  <img
                    src="assets/images/hairextention.jpg"
                    alt="news image"
                    class="img-fluid service-image"
                  />
                  <div class="ab-info-con">
                    <h4>{t('services.hairExtensions')}</h4>
                  </div>
                </div>
              </div>
              <div class="col-md-3 ab-content">
                <div class="ab-content-inner">
                  <img
                    src="assets/images/keratine.jpg"
                    alt="news image"
                    class="img-fluid service-image"
                  />
                  <div class="ab-info-con">
                    <h4>{t('services.keratinStraightening')}</h4>
                  </div>
                </div>
              </div>
              <div class="col-md-3 ab-content">
                <div class="ab-content-inner">
                  <img
                    src="assets/images/hairstyle.jpg"
                    alt="news image"
                    class="img-fluid service-image"
                  />
                  <div class="ab-info-con">
                    <h4>{t('services.hairstyles')}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- //services -->

                <!-- pricing --> */}
        {/* Pricing section with updated services and prices */}
        <section class="pricing py-5">
          <div class="container py-md-5">
            <h3 class="heading text-capitalize text-center mb-3 mb-sm-5">
			{t('pricing')}
            </h3>
            <div class="row justify-content-center">
              <div class="col-md-8">
                <div class="menu-item">
                  <div class="row border-dot no-gutters">
                    <div class="col-8 menu-item-name">
                      <h6>{t('services.mensHaircut')}</h6>
                    </div>
                    <div class="col-4 menu-item-price text-right">
                      <h6>300-500 Kč</h6>
                    </div>
                  </div>
                </div>
                <div class="menu-item my-4">
                  <div class="row border-dot no-gutters">
                    <div class="col-8 menu-item-name">
                      <h6>{t('services.womensHaircut')}</h6>
                    </div>
                    <div class="col-4 menu-item-price text-right">
                      <h6>500-700 Kč</h6>
                    </div>
                  </div>
                </div>
                <div class="menu-item">
                  <div class="row border-dot no-gutters">
                    <div class="col-8 menu-item-name">
                      <h6>{t('services.hairColoring')}</h6>
                    </div>
                    <div class="col-4 menu-item-price text-right">
                      <h6>900-3000 Kč</h6>
                    </div>
                  </div>
                </div>
                <div class="menu-item my-4">
                  <div class="row border-dot no-gutters">
                    <div class="col-8 menu-item-name">
                      <h6>{t('services.hairExtensions')}</h6>
                    </div>
                    <div class="col-4 menu-item-price text-right">
                      <h6>3000 Kč</h6>
                    </div>
                  </div>
                </div>
                <div class="menu-item">
                  <div class="row border-dot no-gutters">
                    <div class="col-8 menu-item-name">
                      <h6>{t('services.keratinStraightening')}</h6>
                    </div>
                    <div class="col-4 menu-item-price text-right">
                      <h6>2000-4000 Kč</h6>
                    </div>
                  </div>
                </div>
                <div class="menu-item my-4">
                  <div class="row border-dot no-gutters">
                    <div class="col-8 menu-item-name">
                      <h6>{t('services.hairstyles')}</h6>
                    </div>
                    <div class="col-4 menu-item-price text-right">
                      <h6>800-2000 Kč</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- //pricing --> */}
        {/* <!--/order-now--> */}
		<section className="order-sec py-5">
        <div className="container py-md-5">
          <div className="test-info text-center">
            <h3 className="tittle order">
              <span>{t('orderSection.callToBook')}</span> {t('orderSection.ifBusy')}
            </h3>
            <a
              href="tel:+420775604390"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <h4 className="tittle my-2">{t('orderSection.phoneNumber')}</h4>
            </a>
            <div className="read-more mx-auto m-0 text-center">
              <Link to="/contact" className="read-more scroll btn">
                {t('orderSection.clickHere')}
              </Link>
            </div>
          </div>
        </div>
      </section>
      </div>
    );
  }
}
export default withTranslation()(Home);
