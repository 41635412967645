import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './Navbar.css';


class Navbar extends Component {
    changeLanguage = (language) => {
        const { i18n } = this.props;
        i18n.changeLanguage(language);
    };

    render(){
        const { t, i18n } = this.props; // Destructure t function for translation

        return(
            <header>
                <div class="container">
                    <div class="header d-lg-flex justify-content-between align-items-center">
                        <div class="header-agile">
				
                            <h1>
                                <Link to='/' class="navbar-brand logo">
                                    <span class="fa fa-scissors" aria-hidden="true"></span> Alexandr Oshlakov
                                </Link>
                            </h1>
                        </div>
                        <div>
                            <nav>
                                <label for="drop" class="toggle mt-lg-0 mt-1"><span class="fa fa-bars" aria-hidden="true"></span></label>
                                <input type="checkbox" id="drop" />
                                    <ul class="menu">
                                        <li class="mr-lg-3 mr-2 active"><Link to='/'>{t('home')}</Link></li>
                                        <li class="mr-lg-3 mr-2"><Link to='/gallery'>{t('galleryBar')}</Link></li>
                                        <li class="mr-lg-3 mr-2"><Link to='/contact'>{t('contact')}</Link></li>
										<li class="mr-lg-3 mr-2 active"><select onChange={(e) => this.changeLanguage(e.target.value)} value={i18n.language}>
								<option value="en">English</option>
								<option value="cs">Čeština</option>
								<option value="ru">Русский</option>
							</select></li>
                                    </ul>
							{/* <select onChange={(e) => this.changeLanguage(e.target.value)} value={i18n.language} class="navbar-btn; fa fa-bars">
								<option value="en">English</option>
								<option value="cs">Čeština</option>
								<option value="ru">Русский</option>
							</select> */}
							</nav>
                        </div>
						
                    </div>
                </div>
            </header>
        )
    }
}

export default withTranslation()(Navbar);
