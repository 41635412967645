import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
      translation: {
        "homePage": {
          "aboutMe": "About Me",
          "bannerTitle1": "Creating and Renovating Hair Style Trends",
          "bannerDesc1": "As a professional stylist, I am passionate about creating and renovating the latest hair trends. I'm dedicated to helping you look and feel your best.",
          "getAppointment": "Get appointment",
          "bannerTitle2": "Helping You Grow Your Hair",
          "bannerDesc2": "Experience personalized hair and beard care tailored to your needs.",
          "bannerTitle3": "Designing and Creating Latest Hair Styles",
          "bannerDesc3": "Experience personalized hair care and styling services designed to suit your unique style.",
          "bannerTitle4": "Designing Latest Trending Haircuts",
          "bannerDesc4": "Experience personalized haircuts and styling services tailored to your individual preferences.",
          "aboutMePar1": "Welcome to my journey in hairstyling, a passion that has defined my career for nearly 20 years. Originally from Kazakhstan, I have made Prague my home, where I've continued to refine my craft and establish a name for myself in the hairstyling world.",
          "aboutMePar2": "My roots in Kazakhstan instilled in me a love for artistic expression through hair, blending traditional styles with modern flair. Moving to Prague enriched my perspective, allowing me to immerse myself in a city known for its beauty and history. This transition has been pivotal in my growth as a stylist, inspiring me to explore new trends and techniques.",
          "aboutMePar3": "I pride myself on a commitment to continuous learning, ensuring that I offer my clients the latest in cutting, coloring, and styling. My approach goes beyond mere aesthetics; I believe in creating hairstyles that reflect my clients' personalities and lifestyles, enhancing their natural beauty and boosting their confidence.",
          "aboutMePar4": "As I reflect on two decades in this vibrant industry, I am grateful for every experience that has shaped my journey. From Kazakhstan to Prague, each step has been a lesson in creativity, resilience, and the transformative power of a great hairstyle.",
          "aboutMePar5": "Thank you for joining me on this beautiful journey. I look forward to helping you discover your perfect look."
        },
        "services": {
          "heading": "Services",
          "mensHaircut": "Men's Haircut",
          "womensHaircut": "Women's Haircut",
          "hairColoring": "Hair Coloring",
          "hairExtensions": "Hair Extensions",
          "keratinStraightening": "Keratin Straightening",
          "hairstyles": "Hairstyles"
        },
        "pricing" : "Our Pricing",
        "gallery": {
          "breadcrumbHome": "Home",
          "breadcrumbGallery": "Gallery",
          "heading": "Our Gallery",
          "googleGallery": "Google Gallery",
          "yelpGallery": "Yelp Gallery"
          // ... other translations for English
        },
        "home": "Home",
        "galleryBar": "Gallery",
        "contact": "Contact",
        "contact_description": "Booking appointments can be made by phone only. However, feel free to contact me on social media for any queries or additional information.",
        "footer": {
          "name": "Salon Sarah",
          "description": "Is the place I currently work in.",
          "location": "The salon is located in the center of Prague, in the Vinohrady district. It is a cozy and comfortable place where you can relax and enjoy the atmosphere.",
          "address": "Adresa: Mánesova 1598/60, 120 00 Praha, Vinohrady",
          "openingHours": "Opening Hours",
          "available": "Available on the phone every day from 8 am to 9 pm",
          "contactUs": "Contact Us",
          "email": "oshlakov@seznam.cz",
          "phone": "+420 775 604 390"
          // ... other translations
      },
      "orderSection": {
        "callToBook": "CALL ME TO BOOK AN APPOINTMENT",
        "ifBusy": "If I do not pick up I might be busy so I will call you back to book the appointment",
        "phoneNumber": "+420 775 604 390",
        "clickHere": "Click here"
      }
      }
    },
    cs: {
      translation: {
        "homePage": {
          "aboutMe": "O Mně",
          "bannerTitle1": "Tvorba a renovace trendů účesů",
          "bannerDesc1": "Jako profesionální stylistka jsem vášnivá v tvorbě a obnově nejnovějších trendů v účesech. Jsem zde, abych vám pomohla vypadat a cítit se co nejlépe.",
          "getAppointment": "Objednat se",
          "bannerTitle2": "Pomoc s růstem vašich vlasů",
          "bannerDesc2": "Zažijte personalizovanou péči o vlasy a vousy přizpůsobenou vašim potřebám.",
          "bannerTitle3": "Návrh a tvorba nejnovějších účesů",
          "bannerDesc3": "Zažijte personalizované služby péče o vlasy a styling, které jsou přizpůsobeny vašemu jedinečnému stylu.",
          "bannerTitle4": "Návrh nejnovějších trendy střihů",
          "bannerDesc4": "Zažijte personalizované střihy a stylingové služby přizpůsobené vašim individuálním preferencím.",
          "aboutMePar1": "Vítejte na mé cestě v oblasti účesů, vášeň, která definovala mou kariéru téměř 20 let. Původem z Kazachstánu jsem si udělala domov v Praze, kde jsem pokračovala v zdokonalování svého řemesla a vybudovala si jméno ve světě kadeřnictví.",
          "aboutMePar2": "Mé kořeny v Kazachstánu ve mě vzbudily lásku k uměleckému vyjádření prostřednictvím vlasů, kombinaci tradičních stylů s moderním šarmem. Přestěhování do Prahy obohatilo můj pohled, což mi umožnilo ponořit se do města známého svou krásou a historií. Tento přechod byl zásadní pro můj růst jako stylistky, inspiroval mě k prozkoumání nových trendů a technik.",
          "aboutMePar3": "Jsem hrdá na svůj závazek k neustálému učení, což mi umožňuje nabízet klientům nejnovější služby v oblasti střihu, barvení a stylingu. Můj přístup přesahuje pouhou estetiku; věřím ve vytváření účesů, které odrážejí osobnosti a životní styl mé klientely, zvyšují jejich přirozenou krásu a posilují jejich sebevědomí.",
          "aboutMePar4": "Když se ohlédnu za dvěma desetiletími v tomto dynamickém odvětví, jsem vděčná za každou zkušenost, která formovala mou cestu. Od Kazachstánu po Prahu, každý krok byl lekcí v kreativitě, odolnosti a proměňující síle skvělého účesu.",
          "aboutMePar5": "Děkuji, že jste se mnou na této krásné cestě. Těším se, až vám pomohu objevit váš dokonalý vzhled."
        },
        "pricing": "Ceník Služeb",
        "services": {
          "heading": "Služby",
          "mensHaircut": "Pánský střih",
          "womensHaircut": "Dámský střih",
          "hairColoring": "Barvení vlasů",
          "hairExtensions": "Prodlužování vlasů",
          "keratinStraightening": "Keratinové vyrovnání",
          "hairstyles": "Účesy"
        },
        "gallery": {
          "breadcrumbHome": "Domů",
          "breadcrumbGallery": "Galerie",
          "heading": "Naše Galerie",
          "googleGallery": "Galerie Google",
          "yelpGallery": "Galerie Yelp"
          // ... other translations for Czech
        },
        "home": "Domů",
        "galleryBar": "Galerie",
        "contact": "Kontakt",
        "contact_description": "Rezervace termínů můžete provádět pouze telefonicky. Neváhejte mě však pro jakékoli dotazy nebo další informace kontaktovat na sociálních sítích.",
        "footer": {
          "name": "Salon Sarah",
          "description": "Je to místo, kde aktuálně pracuji.",
          "location": "Salon se nachází v centru Prahy, ve čtvrti Vinohrady. Je to útulné a pohodlné místo, kde si můžete odpočinout a užít si atmosféru.",
          "address": "Adresa: Mánesova 1598/60, 120 00 Praha, Vinohrady",
          "openingHours": "Otevírací Doba",
          "available": "Dostupný na telefonu každý den od 8 do 21 hodin",
          "contactUs": "Kontaktujte Nás",
          "email": "oshlakov@seznam.cz",
          "phone": "+420 775 604 390"
        },
        "orderSection": {
          "callToBook": "ZAVOLEJTE MI A DOMLUVTE SI SCHŮZKU",
          "ifBusy": "Pokud nezvedám, mohu být zaneprázdněn, takže vám zavolám zpět a domluvíme si schůzku",
          "phoneNumber": "+420 775 604 390",
          "clickHere": "Klikněte zde"
        }
      }
    },
    ru: {
      translation: {
          "homePage": {
            "aboutMe": "Обо мне",
            "bannerTitle1": "Создание и обновление трендов причесок",
            "bannerDesc1": "Как профессиональный стилист, я страстно занимаюсь созданием и обновлением последних тенденций в прическах. Я посвящаю себя тому, чтобы помогать вам выглядеть и чувствовать себя наилучшим образом.",
            "getAppointment": "Записаться на прием",
            "bannerTitle2": "Помогаем вашим волосам расти",
            "bannerDesc2": "Получите персонализированный уход за волосами и бородой, адаптированный к вашим потребностям.",
            "bannerTitle3": "Дизайн и создание последних причесок",
            "bannerDesc3": "Опыт персонализированного ухода за волосами и услуг по стайлингу, разработанных для подчеркивания вашего уникального стиля.",
            "bannerTitle4": "Создание последних модных стрижек",
            "bannerDesc4": "Опыт персонализированных стрижек и услуг по стайлингу, адаптированных к вашим индивидуальным предпочтениям.",
            "aboutMePar1": "Добро пожаловать в мое путешествие в мире парикмахерского искусства, страсть которого определила мою карьеру на протяжении почти 20 лет. Родом из Казахстана, я сделал Прагу своим домом, где продолжаю совершенствовать свое мастерство и зарекомендовать себя в мире стиля.",
            "aboutMePar2": "Мои корни в Казахстане привили мне любовь к художественному выражению через волосы, сочетая традиционные стили с современным флером. Переезд в Прагу обогатил мой взгляд, позволив мне погрузиться в город, известный своей красотой и историей. Этот переход стал ключевым в моем развитии как стилиста, вдохновляя меня изучать новые тенденции и техники.",
            "aboutMePar3": "Я горжусь своей приверженностью постоянному обучению, гарантируя, что я предлагаю своим клиентам последнее слово в стрижке, окрашивании и стайлинге. Мой подход выходит за рамки простой эстетики; я верю в создание причесок, которые отражают личность и образ жизни моих клиентов, улучшая их естественную красоту и повышая уверенность в себе.",
            "aboutMePar4": "Отражаясь на два десятилетия в этой динамичной индустрии, я благодарен за каждый опыт, который сформировал мой путь. От Казахстана до Праги, каждый шаг был уроком креативности, устойчивости и трансформационной силы великолепной прически.",
            "aboutMePar5": "Спасибо, что присоединились ко мне в этом прекрасном путешествии. С нетерпением жду, когда помогу вам открыть для себя ваш идеальный образ."
          },
          "pricing": "Наши Цены",
        "services": {
          "heading": "Услуги",
          "mensHaircut": "Стрижка для мужчин",
          "womensHaircut": "Стрижка для женщин",
          "hairColoring": "Окрашивание волос",
          "hairExtensions": "Наращивание волос",
          "keratinStraightening": "Кератиновое выпрямление",
          "hairstyles": "Прически"
        },
        "gallery": {
          "breadcrumbHome": "Главная",
          "breadcrumbGallery": "Галерея",
          "heading": "Наша Галерея",
          "googleGallery": "Галерея Google",
          "yelpGallery": "Галерея Yelp"
          // ... other translations for Russian
        },
        "home": "Главная",
        "galleryBar": "Галерея",
        "contact": "Контакт",
        "contact_description": "Бронирование визитов возможно только по телефону. Однако не стесняйтесь обращаться ко мне в социальных сетях для любых вопросов или дополнительной информации.",
        "footer": {
          "name": "Salon Sarah",
          "description": "Это место, где я в настоящее время работаю.",
          "location": "Салон расположен в центре Праги, в районе Винограды. Это уютное и комфортабельное место, где вы можете расслабиться и насладиться атмосферой.",
          "address": "Адрес: Mánesova 1598/60, 120 00 Praha, Vinohrady",
          "openingHours": "Часы Работы",
          "available": "Доступен по телефону каждый день с 8 утра до 9 вечера",
          "contactUs": "Свяжитесь с Нами",
          "email": "oshlakov@seznam.cz",
          "phone": "+420 775 604 390"
        },
        "orderSection": {
          "callToBook": "ПОЗВОНИТЕ МНЕ, ЧТОБЫ ЗАПИСАТЬСЯ НА ПРИЕМ",
          "ifBusy": "Если я не отвечу, возможно, я занят, поэтому я перезвоню вам, чтобы записать вас на прием",
          "phoneNumber": "+420 775 604 390",
          "clickHere": "Нажмите здесь"
        }
      }
    }
  };
  

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // Passes the LanguageDetector to use
  .init({
    resources,
    fallbackLng: 'en', // Use English as the fallback language
    detection: {
      order: ['navigator'], // Use the browser language setting
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
