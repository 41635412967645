import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './Gallery.css';
class Gallery extends Component {
    render() {
        const { t } = this.props; // Destructure the t function for translation

        return (
            <div>
                <section className="inner-page-banner" id="home"></section>

                <div className="breadcrumb-agile">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to='/'>{t('gallery.breadcrumbHome')}</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{t('gallery.breadcrumbGallery')}</li>
                    </ol>
                </div>
                <section className="gallery py-5" id="gallery">
                    <div className="container py-md-5">
                        <h3 className="heading text-center mb-3 mb-sm-5">{t('gallery.heading')}</h3>
                              {/* Add links to other galleries */}
                              <div className="other-galleries">
                                <a href="https://www.google.com/maps/uv?pb=!1s0x470b95b8ac4399ef%3A0x6823d3ccac8b8d44!3m1!7e131!4s!5zQWxleGFuZHIgT3NobGFrb3YgS2FkZcWZbsOtay_Qn9Cw0YDQuNC60LzQsNGF0LXRgA!15sCgIgAQ&hl=en&imagekey=!1e10!2sAF1QipMu5ruyGrkUv8p8TPnhUfFukGJCvy9MxGeCvtLF" className="gallery-link">{t('gallery.googleGallery')}</a>
                                <a href="https://www.yelp.com/biz_photos/alexandr-oshlakov-kade%C5%99n%C3%ADk-praha" className="gallery-link">{t('gallery.yelpGallery')}</a>
                            </div>
            <div class="gallery-content">
                <div class="row">
                    <div class="col-md-4 col-sm-6 gal-img">
                        <a href="#gal1"><img src="assets/images/haircolor.jpg" alt="aegis" class="img-fluid mt-4" /></a>
                    </div>
                    <div class="col-md-4 col-sm-6 gal-img">
                        <a href="#gal2"><img src="assets/images/hairextention.jpg" alt="aegis" class="img-fluid mt-4" /></a>
                    </div>
                    <div class="col-md-4 col-sm-6 gal-img">
                        <a href="#gal3"><img src="assets/images/hairstyle.jpg" alt="aegis" class="img-fluid mt-4" /></a>
                    </div>
                    <div class="col-md-4 col-sm-6 gal-img">
                        <a href="#gal4"><img src="assets/images/keratine.jpg" alt="aegis" class="img-fluid mt-4" /></a>
                    </div>
                    <div class="col-md-4 col-sm-6 gal-img">
                        <a href="#gal5"><img src="assets/images/womanhaircut.jpg" alt="aegis" class="img-fluid mt-4" /></a>
                    </div>
                    <div class="col-md-4 col-sm-6 gal-img">
                        <a href="#gal6"><img src="assets/images/g7.jpg" alt="aegis" class="img-fluid mt-4" /></a>
                    </div>
                    <div class="col-md-4 col-sm-6 gal-img">
                        <a href="#ga17"><img src="assets/images/g8.jpg" alt="aegis" class="img-fluid mt-4" /></a>
                    </div>

                </div>
                <div id="gal1" class="popup-effect">
                    <div class="popup">
                        <img src="assets/images/haircolor.jpg" alt="Popup image" class="img-fluid mt-4" />
                        <a class="close" href="#gallery">&times;</a>
                    </div>
                </div>
                <div id="gal2" class="popup-effect">
                    <div class="popup">
                        <img src="assets/images/hairextention.jpg" alt="Popup image" class="img-fluid mt-4" />
                        <a class="close" href="#gallery">&times;</a>
                    </div>
                </div>
                <div id="gal3" class="popup-effect">
                    <div class="popup">
                        <img src="assets/images/hairstyle.jpg" alt="Popup image" class="img-fluid mt-4" />
                        <a class="close" href="#gallery">&times;</a>
                    </div>
                </div>
                <div id="gal4" class="popup-effect">
                    <div class="popup">
                        <img src="assets/images/keratine.jpg" alt="Popup image" class="img-fluid mt-4" />
                        <a class="close" href="#gallery">&times;</a>
                    </div>
                </div>
                <div id="gal5" class="popup-effect">
                    <div class="popup">
                        <img src="assets/images/womanhaircut.jpg" alt="Popup image" class="img-fluid mt-4" />
                        <a class="close" href="#gallery">&times;</a>
                    </div>
                </div>
                <div id="gal6" class="popup-effect">
                    <div class="popup">
                        <img src="assets/images/g7.jpg" alt="Popup image" class="img-fluid mt-4" />
                        <a class="close" href="#gallery">&times;</a>
                    </div>
                </div>
                <div id="gal7" class="popup-effect">
                    <div class="popup">
                        <img src="assets/images/g8.jpg" alt="Popup image" class="img-fluid mt-4" />
                        <a class="close" href="#gallery">&times;</a>
                    </div>
                </div>

            </div>
        </div>


    </section>
    </div>
        )
    }
}
export default withTranslation()(Gallery);