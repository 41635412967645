import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Contact extends Component {
    render() {
        const { t } = this.props; // Destructure t function for translation

        return (
            <div>
                <section className="inner-page-banner" id="home">
                </section>
                <div className="breadcrumb-agile">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to='/'>{t('home')}</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{t('contact')}</li>
                    </ol>
                </div>
                <section className="content-info py-5">
                    <div className="container py-md-5">
                        <div className="text-center px-lg-5">
                            <h3 className="heading text-center mb-3 mb-sm-5">{t('contact')}</h3>
                            <div className="title-desc text-center px-lg-5">
                                <p className="px-lg-5 sub-wthree">{t('contact_description')}</p>
                                <a href="tel:+420775604390" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <p>+420 775 604 390</p>
                                </a>
                            </div>
                            <div className="social-icons mt-3">
                                <a href="mailto:oshlakov@seznam.cz" className="mr-2"><i className="fa fa-envelope"></i></a>
                                <a href="https://www.facebook.com/alexandr.oshlakov.5" target="_blank" className="mr-2"><i className="fa fa-facebook-f"></i></a>
                                <a href="https://www.instagram.com/alexandroshlakov/" target="_blank" className="mr-2"><i className="fa fa-instagram"></i></a>
                                <a href="http://vk.com/club14782212" target="_blank" className="mr-2"><i className="fa fa-vk"></i></a>
                            </div>
                        </div>
                    </div>
                </section>
    <div class="map-w3layouts">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.4658951301776!2d14.440697676216939!3d50.07756351414282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b9485288b32d7%3A0x927f03fde01a2bb5!2sM%C3%A1nesova%201598%2F60%2C%20120%2000%20Vinohrady%2C%20Czechia!5e0!3m2!1sen!2sdk!4v1707591612685!5m2!1sen!2sdk" allowfullscreen=""></iframe>
        </div>
            </div>
        );
    }
}

export default withTranslation()(Contact);